@use "@scss/common" as *;

.card {
  position: relative;
}

.cardContent {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: base();
  color: var(--color-white);
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 2px;

  @include mid-break {
    padding: var(--mobile-gutter);
  }
}

.leader {
  margin-bottom: base(0.5);
}

.title {
  @include h5;
  margin-bottom: 0;
}

.description {
  @include small;
  flex-grow: 1;
  margin: 0;
  margin-top: base(0.25);
  color: var(--color-gray-4);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cta {
  @include small;
  margin-top: base(0.25);
}
