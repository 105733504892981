@use "./vars.scss" as *;

@mixin mid-break {
  @media (max-width: $breakpoint-m-width) {
    @content;
  }
}

@mixin large-break {
  @media (max-width: $breakpoint-l-width) {
    @content;
  }
}

@mixin x-large-break {
  @media (max-width: $breakpoint-xl-width) {
    @content;
  }
}

