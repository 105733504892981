@use "./functions.scss" as *;
@use "./queries.scss" as *;
@use "./vars.scss" as *;

@mixin jumbo {
  font-size: base(3);
  line-height: 1;
  font-weight: 600;
  margin: 0 0 base();

  @include x-large-break {
    font-size: base(2.75);
  }

  @include large-break {
    font-size: base(2);
  }

  @include mid-break {
    font-size: base(1.5);
  }
}

@mixin h1 {
  font-size: base(1.5);
  line-height: 1.125;
  margin: 0 0 base();
  font-weight: 600;

  @include mid-break {
    font-size: base(1);
    margin: 0 0 base(.5);
  }
}

@mixin h2 {
  font-size: base(1.15);
  line-height: 1.25;
  font-weight: 600;
  margin: 0 0 base(.75);

  @include mid-break {
    font-size: base(.8);
    margin: 0 0 base(.5);
  }
}

@mixin h3 {
  font-size: base(.85);
  line-height: 1.25;
  font-weight: 600;
  margin: 0 0 base(.75);

  @include mid-break {
    font-size: base(0.7);
    margin: 0 0 base(.5);
  }
}

@mixin h4 {
  font-size: base(.65);
  line-height: 1.25;
  font-weight: 600;
  margin: 0 0 base(0.5);

  @include mid-break {
    font-size: base(.65);
  }
}

@mixin h5 {
  font-size: base(0.5);
  line-height: 1.25;
  font-weight: 600;
  margin: 0 0 base(0.5);

  @include mid-break {
    font-size: inherit;
  }
}

@mixin h6 {
  font-size: inherit;
  line-height: 1.25;
  font-weight: 600;
  margin: 0 0 base(0.5);
}

@mixin large {
  font-size: base(0.625);
  line-height: 1.25;
  margin: 0 0 base(0.75);
  letter-spacing: normal;
  font-weight: normal;

  @include mid-break {
    font-size: base(0.65);
  }
}

@mixin p {
  margin: 0 0 base(0.5);
  letter-spacing: normal;
}

@mixin a {
  margin: base() 0 base(0.1);
}

@mixin ol {
  margin: base(0.5) 0 base(0.5);
  padding-left: base(0.5);
}

@mixin ul {
  margin: base(0.5) 0 base(0.5);
  padding-left: base(0.5);
}

@mixin li {
  margin-bottom: base(0.125);
}

@mixin code {
  font-family: "JetBrains Mono", monospace;
  font-size: 16px;
}

@mixin small {
  font-size: 16px;
  line-height: 1.25;
}
