@use "@scss/common" as *;

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  // @include h1;
  margin-top: base();
  margin-bottom: base(3);

  @include x-large-break {
    margin-top: base(2);
    margin-bottom: base(4);
  }

  @include mid-break {
    margin-top: base(0.75);
    margin-bottom: base(1.75);
  }
}

.headingText {
  @include jumbo;
  margin-bottom: base(1.5);
  @include large-break {
    margin-bottom: base();
  }
  @include mid-break {
    margin-bottom: base(0.5);
  }
}

.description {
  @include mid-break {
    margin-bottom: base(0.25);
  }
}

.componentsContainer {
  padding: base(2) 0;
  position: relative;

  @include large-break {
    padding: base(1.5) 0;
  }

  @include mid-break {
    padding: base() 0;
  }
}

.backgroundGrid {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.backgroundCell {
  width: 100%;
  height: 100%;
  position: relative;

  @include large-break {
    padding: 0;
  }

  @include mid-break {
    padding: 0;
  }
}

.background {
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-10);
}

.label {
  @include small;
  font-weight: bold;
  color: var(--color-gray-3);
  margin-bottom: base();

  @include large-break {
    margin-bottom: base();
  }

  @include mid-break {
    display: none;
  }
}

.components {
  position: relative;
  margin-bottom: base(1.75);

  @include mid-break {
    margin-bottom: base(0.25);
  }
}

.cards {
  padding: base(1) 0;
  @include mid-break {
    padding: 0;
  }
}

.cardsInner {
  display: flex;
  flex-wrap: wrap;
  margin: base(-1.5);

  & > * {
    margin: base(0.5);
    width: calc(33.33% - #{base()});
  }

  @include large-break {
    & > * {
      width: calc(50% - #{base()});
    }
  }

  @include mid-break {
    margin: 0;

    & > * {
      margin: 0;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: base(0.25);
      }
    }
  }
}

.utilities {
  position: relative;
}

.below {
  color: var(--color-white);
  margin-top: base(2)
}
